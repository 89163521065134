import React, { useState, useEffect, useRef, useContext } from "react"
import axios from "axios"

import { navigate } from "gatsby"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Context(s) */
import EmailContext from "~context/email"

import { useForm } from "react-hook-form"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"
import isEqual from "lodash.isequal"

/* Import Global Hook(s) */
import usePreviousValue from "~hooks/usePreviousValue"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

import "./account-login-form.scss"

const AccountApplyForm = ({}) => {

  const [emailAddress, setEmailAddress] = useContext(EmailContext)

  const [feedback, setFeedback] = useState()
  const [linkIsShown, showLink] = useState()
	const formRef = useRef()
	const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: 'onChange'
  })
  const allFieldsState = watch()
  const previousAllFieldsState = usePreviousValue(allFieldsState)


  useEffect(() => {
    if (feedback) {
      if (!isEqual(allFieldsState, previousAllFieldsState)) {
        setFeedback()
      }
    }
  }, [allFieldsState, previousAllFieldsState, feedback, setFeedback])

  const onSubmit = async ({email}) => {
    
    console.log('checking email: ')
    console.log(email)


    setFeedback('Processing...')

    axios
    .post("/.netlify/functions/vipUsers", {
      contents: {email: email},
      path: "/vips/auth/check"
    })
    .then(res => {
      console.log('successful request: ')
      console.log(res.data)

      if(res.data.data.exists){
        
        if(res.data.data.message){
          setFeedback(res.data.data.message)
        }else{
          setFeedback('A contact record is already associated with this email. If you are a VIP guest, please login.')
        }

        
        // setFeedback('A contact record is already associated with this email address. If you are approved for VIP status, you will receive your digital VIP pass via email in the lead up to the fair.')

        const contents = { email: email }

        // console.log(contents)
        return
        axios
        .post("/.netlify/functions/vipUsers", {
          contents: contents,
          path: "/vips/auth/forgot-password"
        })
        .then(results => {
          console.log(results)
          setFeedback('A contact record is already associated with this email. If you are a VIP guest, please login')
          // setFeedback('A contact record is already associated with this email address. If you are approved for VIP status, you will receive your digital VIP pass via email in the lead up to the fair.')
          // setFeedback('An account is already associated with this email. You will receive a password reset link shortly.')
        })
        .catch(e => {
          setFeedback('There was an error processing your request.')
          console.log('error', e)
          console.error(e)          
        })


      }else{
        setFeedback('Redirecting...')
        if (typeof window !== `undefined`) {

          setEmailAddress(email)

          // setFeedback('An account is not yet registered with this email address.')
          // showLink(true)

          navigate("/vip/apply/application")
        }
      }

    })
    .catch(e => {
      console.error(e)
    })

  };


	return (
    <form onSubmit={handleSubmit(onSubmit)} className="account-login" ref={formRef}>
    	<input
    		type="email"
        placeholder="Email Address"
        {...register("email", { required: true, value: ""})}
    	/>
      <input
      	type="submit"
      	placeholder="Continue"
      	value="Continue"
      	title="Continue"
      />

      {feedback && <p className="font-size--small">{feedback}</p>}
      {linkIsShown && <p className="font-size--small">
        <Link to="/vip/apply/application">Apply for VIP status</Link>
      </p>}

    </form>
  )
}


export default AccountApplyForm